import { Collapse } from '@/shared/common/collapse/collapse';
import { FormSelect } from '@/shared/form/form-element/form-select/form-select';
import { swiperThumb } from '@/entities/swiper-thumb/swiper-thumb';

export const productCard = (container) => {
	const swiper = container.querySelector('.js-swiper-thumb');
	if (swiper) swiperThumb(swiper);

	new Collapse(container, {
		selectorElement: '.js-product-card',
		selectorButton: '.js-product-card__button',
		selectorContent: '.js-product-card__content',
	});

	container.querySelectorAll('.js-model-analogue').forEach(($element) => {
		new Collapse($element, {
			selectorElement: '.js-model-analogue',
			selectorButton: '.js-model-analogue__button',
			selectorContent: '.js-model-analogue__content',
		});
	});

	const $select = container.querySelector('.js-form-select select');
	const $models = Array.from(container.querySelectorAll('.js-product-model'));

	if ($select) {
		new FormSelect($select);

		$models.forEach(($element) => {
			if ($element.dataset.value === $select.value) {
				$element.classList.add('selected');
			}
		});

		$select.addEventListener('elementChange', (event) => {
			const value = event.detail.data.value;
			const $active = $models.find(($element) => $element.classList.contains('selected'));
			const $current = $models.find(($element) => $element.dataset.value === value);
			$active.classList.remove('selected');
			$current.classList.add('selected');
		});
	}
};

import Swiper from 'swiper';
import { Pagination, Thumbs } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/thumbs';
import 'swiper/css/pagination';

export const swiperThumb = (container) => {
	const thumb = new Swiper(container.querySelector('.js-swiper-thumb__thumb'), {
		direction: 'vertical',
		slidesPerView: 'auto',
		spaceBetween: 0,
	});

	new Swiper(container.querySelector('.js-swiper-thumb__main'), {
		modules: [Pagination, Thumbs],
		slidesPerView: 1,
		spaceBetween: 0,
		pagination: {
			el: container.querySelector('.js-swiper-thumb__pagination'),
			clickable: true,
		},
		thumbs: {
			swiper: thumb,
		},
	});
};

import '@/app/app.css';
import './catalog.css';

import { productCard } from '@/features/product/product-card/product-card';

import '@/app/app';

window.app.productCard = productCard;

window.addEventListener('DOMContentLoaded', () => {
	const $products = document.querySelectorAll('.js-product-card');
	const $ctrlVue = document.querySelector('.js-ctrl-vue');
	const $ctrlVueCollapse = $ctrlVue.querySelector('.js-ctrl-vue__collapse');
	const $ctrlVueTitle = $ctrlVue.querySelector('.js-ctrl-vue__title');

	$products.forEach((element) => {
		productCard(element);
	});

	$ctrlVueCollapse?.addEventListener('click', () => {
		const isActive = $ctrlVueCollapse.classList.contains('active');
		$ctrlVueCollapse.classList.toggle('active');
		$ctrlVue.dataset.collapse = isActive;

		$products.forEach(($element) => {
			const productCollapse = app.classInstance.get($element);
			productCollapse.collapse.toggle($element, isActive);
		});
	});

	$ctrlVueTitle?.addEventListener('click', () => {
		$ctrlVueTitle.classList.toggle('active');
		$ctrlVue.dataset.title = $ctrlVueTitle.classList.contains('active');
	});
});
